import { ThreeDTileContainer, TilesLoader, useThreeDTilesContext } from "@bigplutolabs/drone"
import { Canvas } from "@react-three/fiber"
import React, { useEffect, useRef, useState } from "react"
import { CircularProgressBar, People, RadarAnimation } from "./components"
import { tiles } from "./data/tiles"
import { LoadingPage } from "./pages/Loading"
import { ThreeDTile } from "./types"

export const App: React.FC = () => {
	const [{ currentTile }] = useThreeDTilesContext()

	const videoRef = useRef<HTMLVideoElement>()
	const [isVideoLoaded, setIsVideoLoaded] = useState(false)
	const [isVideoEnded, setIsVideoEnded] = useState(false)
	const [shouldHideVideo, setShouldHideVideo] = useState(false)

	useEffect(() => {
		if (currentTile && videoRef.current) {
			videoRef.current.play()
		}
	}, [currentTile, videoRef.current])

	useEffect(() => {
		let timeout: NodeJS.Timeout
		if (isVideoEnded) {
			timeout = setTimeout(() => {
				setShouldHideVideo(true)
			}, 300)
		}

		return () => {
			if (timeout) clearTimeout(timeout)
		}
	}, [isVideoEnded, videoRef.current])

	const [tilesLoadingProgress, setTilesLoadingProgress] = useState(0)

	return (
		<div style={{ width: "100vw", height: "100dvh", overflow: "hidden" }}>
			{!currentTile && !isVideoLoaded ? <LoadingPage /> : <></>}
			{!shouldHideVideo && (
				<div style={{ opacity: isVideoEnded || !currentTile ? 0 : 1, transition: "opacity 300ms ease-in-out", position: "fixed", top: 0, right: 0, zIndex: 10, width: "100vw", height: "100dvh", overflow: "hidden" }}>
					<video ref={videoRef as any} src="/vid.mp4" preload="auto" muted onLoad={() => setIsVideoLoaded(true)} onEnded={() => setIsVideoEnded(true)} width="100%" height="100%" style={{ objectFit: "cover" }} />
				</div>
			)}

			{!currentTile && (
				<div style={{ position: "fixed", top: 0, right: 0, width: "100vw", height: "90dvh", display: "flex", alignItems: "flex-end", justifyContent: "center" }}>
					<CircularProgressBar maxValue={100} selectedValue={tilesLoadingProgress} backgroundColor="#A54DFF" activeStrokeColor="#ffffff" strokeWidth={24} textColor="#ffffff" />
				</div>
			)}

			<Canvas style={currentTile && isVideoEnded ? { width: "100vw", height: "100dvh" } : {}}>
				<TilesLoader tiles={tiles} onProgress={(progress) => setTilesLoadingProgress(progress)} />
				{currentTile ? (
					<ThreeDTileContainer>
						{(currentTile as ThreeDTile).tileNode.zones?.map((zone) => (
							<>
								{zone.constraints !== undefined && zone.elevation !== undefined && <People key={currentTile.tileNode.id + zone.id} constraints={zone.constraints} elevation={zone.elevation} males={10} females={10} />}
								{zone.readers?.map((reader) => (
									<RadarAnimation key={currentTile.tileNode.id + reader.id} isActive position={reader.position} args={reader.size} />
								))}
							</>
						))}
					</ThreeDTileContainer>
				) : (
					<></>
				)}
			</Canvas>
		</div>
	)
}
