import React from "react"
import Lottie from "react-lottie"
import * as loadingAnimation from "../animations/loading.json"

export const LoadingPage: React.FC = () => {
	return (
		<div style={{ height: "100dvh", width: "100vw", backgroundColor: "#A54DFF" }}>
			<Lottie
				options={{
					loop: true,
					autoplay: true,
					animationData: loadingAnimation,
					rendererSettings: {
						preserveAspectRatio: "xMidYMid slice",
					},
				}}
			/>
		</div>
	)
}
