import { degToRad } from "three/src/math/MathUtils"
import { ThreeDTile } from "../types"

export const tiles: ThreeDTile[] = [
	{
		tileNode: {
			id: "1",
			initialRotation: [0, degToRad(-80), 0],
			zones: [
				{
					id: "1",
					constraints: [
						[
							[251, -239],
							[-136, -10],
						],
						[
							[702, -153],
							[275, -40],
						],
						[
							[69, 40],
							[-174, 156],
						],
						[
							[222, 161],
							[-122, 276],
						],
					],
					elevation: -60,
					readers: [{ id: "1", position: [630, 80, -220], size: [4, 32] }],
				},
			],
			url: "https://tcspinner-pictures.s3.ap-south-1.amazonaws.com/sites/64a151aa8b9b167c5b2940c1/threed-tiles/1.jpg",
		},
		east: { id: "2" },
	},
	{
		tileNode: {
			id: "2",
			zones: [
				{
					id: "1",
					readers: [{ id: "1", position: [-900, 90, -40], size: [6, 32] }],
					constraints: [
						[
							[-135, -183],
							[-390, 107],
						],
						[
							[-467, -42],
							[-888, 127],
						],
					],
					elevation: -60,
				},
				{
					id: "2",
					readers: [{ id: "1", position: [500, 160, 600], size: [6, 32] }],
					constraints: [
						[
							[936, -200],
							[-79, 86],
						],
						[
							[504, 228],
							[-140, 389],
						],
						[
							[943, -450],
							[661, -275],
						],
						[
							[875, 277],
							[616, 471],
						],
					],
					elevation: -60,
				},
			],
			url: "https://tcspinner-pictures.s3.ap-south-1.amazonaws.com/sites/64a151aa8b9b167c5b2940c1/threed-tiles/2.jpg",
		},
		west: { id: "1" },
		east: { id: "3" },
		northEast: { id: "34" },
		southEast: { id: "4" },
	},
	{
		tileNode: {
			id: "3",
			zones: [
				{ id: "1", readers: [{ id: "1", position: [-900, 30, -100], size: [4, 32] }] },
				{ id: "2", readers: [{ id: "1", position: [-380, 130, 600], size: [4, 32] }] },
			],
			url: "https://tcspinner-pictures.s3.ap-south-1.amazonaws.com/sites/64a151aa8b9b167c5b2940c1/threed-tiles/3.jpg",
		},
		west: { id: "2" },
		north: { id: "34" },
		south: { id: "4" },
	},
	{
		tileNode: {
			id: "4",
			zones: [
				{ id: "1", readers: [{ id: "1", position: [-200, 65, -190], size: [4, 32] }] },
				{ id: "2", readers: [{ id: "1", position: [0, 10, 500], size: [4, 32] }] },
			],
			url: "https://tcspinner-pictures.s3.ap-south-1.amazonaws.com/sites/64a151aa8b9b167c5b2940c1/threed-tiles/4.jpg",
		},
		north: { id: "3" },
		south: { id: "5" },
		northWest: { id: "2" },
	},
	{
		tileNode: {
			id: "5",
			zones: [
				{ id: "1", readers: [{ id: "1", position: [-200, 55, -550], size: [4, 32] }] },
				{ id: "2", readers: [{ id: "1", position: [-15, 20, 500], size: [4, 32] }] },
			],
			url: "https://tcspinner-pictures.s3.ap-south-1.amazonaws.com/sites/64a151aa8b9b167c5b2940c1/threed-tiles/5.jpg",
		},
		north: { id: "4" },
		south: { id: "6" },
	},
	{
		tileNode: { id: "6", zones: [{ id: "1", readers: [{ id: "1", position: [-45, 10, -150], size: [4, 32] }] }], url: "https://tcspinner-pictures.s3.ap-south-1.amazonaws.com/sites/64a151aa8b9b167c5b2940c1/threed-tiles/6.jpg" },
		east: { id: "7" },
		north: { id: "5" },
	},
	{
		tileNode: { id: "7", zones: [{ id: "1", readers: [{ id: "1", position: [770, 20, -30], size: [4, 32] }] }], url: "https://tcspinner-pictures.s3.ap-south-1.amazonaws.com/sites/64a151aa8b9b167c5b2940c1/threed-tiles/7.jpg" },
		east: { id: "8" },
		west: { id: "6" },
	},
	{
		tileNode: { id: "8", zones: [{ id: "1", readers: [{ id: "1", position: [770, 40, -35], size: [4, 32] }] }], url: "https://tcspinner-pictures.s3.ap-south-1.amazonaws.com/sites/64a151aa8b9b167c5b2940c1/threed-tiles/8.jpg" },
		east: { id: "9" },
		west: { id: "7" },
	},
	{
		tileNode: { id: "9", zones: [{ id: "1", readers: [{ id: "1", position: [770, 80, 120], size: [4, 32] }] }], url: "https://tcspinner-pictures.s3.ap-south-1.amazonaws.com/sites/64a151aa8b9b167c5b2940c1/threed-tiles/9.jpg" },
		east: { id: "10" },
		west: { id: "8" },
	},
	{
		tileNode: {
			id: "10",
			zones: [
				{ id: "1", readers: [{ id: "1", position: [-64, 30, 110], size: [4, 32] }] },
				{ id: "2", readers: [{ id: "1", position: [-10, 15, -500], size: [4, 32] }] },
			],
			url: "https://tcspinner-pictures.s3.ap-south-1.amazonaws.com/sites/64a151aa8b9b167c5b2940c1/threed-tiles/10.jpg",
		},
		north: { id: "11" },
		west: { id: "9" },
	},
	{
		tileNode: {
			id: "11",
			zones: [
				{ id: "1", readers: [{ id: "1", position: [-105, 60, 600], size: [4, 32] }] },
				{ id: "2", readers: [{ id: "1", position: [85, 20, -500], size: [4, 32] }] },
			],
			url: "https://tcspinner-pictures.s3.ap-south-1.amazonaws.com/sites/64a151aa8b9b167c5b2940c1/threed-tiles/11.jpg",
		},
		north: { id: "12" },
		south: { id: "10" },
	},
	{
		tileNode: {
			id: "12",
			zones: [
				{ id: "1", readers: [{ id: "1", position: [-105, 40, 800], size: [4, 32] }] },
				{ id: "2", readers: [{ id: "1", position: [225, 38, -520], size: [4, 32] }] },
			],
			url: "https://tcspinner-pictures.s3.ap-south-1.amazonaws.com/sites/64a151aa8b9b167c5b2940c1/threed-tiles/12.jpg",
		},
		north: { id: "13" },
		south: { id: "11" },
	},
	{
		tileNode: {
			id: "13",
			zones: [
				{ id: "1", readers: [{ id: "1", position: [-70, 40, 1000], size: [4, 32] }] },
				{ id: "2", readers: [{ id: "1", position: [150, 30, -30], size: [4, 32] }] },
			],
			url: "https://tcspinner-pictures.s3.ap-south-1.amazonaws.com/sites/64a151aa8b9b167c5b2940c1/threed-tiles/13.jpg",
		},
		north: { id: "14" },
		south: { id: "12" },
	},
	{ tileNode: { id: "14", url: "https://tcspinner-pictures.s3.ap-south-1.amazonaws.com/sites/64a151aa8b9b167c5b2940c1/threed-tiles/14.jpg" }, north: { id: "15" }, south: { id: "13" } },
	{ tileNode: { id: "15", url: "https://tcspinner-pictures.s3.ap-south-1.amazonaws.com/sites/64a151aa8b9b167c5b2940c1/threed-tiles/15.jpg" }, north: { id: "16" }, south: { id: "14" } },
	{
		tileNode: {
			id: "16",
			zones: [{ id: "1", readers: [{ id: "1", position: [165, 35, -670], size: [4, 32] }] }],
			url: "https://tcspinner-pictures.s3.ap-south-1.amazonaws.com/sites/64a151aa8b9b167c5b2940c1/threed-tiles/16.jpg",
		},
		north: { id: "17" },
		south: { id: "15" },
	},
	{
		tileNode: {
			id: "17",
			zones: [{ id: "1", readers: [{ id: "1", position: [180, 35, -450], size: [4, 32] }] }],
			url: "https://tcspinner-pictures.s3.ap-south-1.amazonaws.com/sites/64a151aa8b9b167c5b2940c1/threed-tiles/17.jpg",
		},
		north: { id: "18" },
		south: { id: "16" },
	},
	{
		tileNode: { id: "18", zones: [{ id: "1", readers: [{ id: "1", position: [200, 40, -5], size: [4, 32] }] }], url: "https://tcspinner-pictures.s3.ap-south-1.amazonaws.com/sites/64a151aa8b9b167c5b2940c1/threed-tiles/18.jpg" },
		north: { id: "19" },
		south: { id: "17" },
	},
	{
		tileNode: { id: "19", zones: [{ id: "1", readers: [{ id: "1", position: [210, 35, 650], size: [4, 32] }] }], url: "https://tcspinner-pictures.s3.ap-south-1.amazonaws.com/sites/64a151aa8b9b167c5b2940c1/threed-tiles/19.jpg" },
		north: { id: "20" },
		south: { id: "18" },
	},
	{
		tileNode: {
			id: "20",
			zones: [{ id: "1", readers: [{ id: "1", position: [-5, 35, -1200], size: [4, 32] }] }],
			url: "https://tcspinner-pictures.s3.ap-south-1.amazonaws.com/sites/64a151aa8b9b167c5b2940c1/threed-tiles/20.jpg",
		},
		north: { id: "21" },
		south: { id: "19" },
	},
	{
		tileNode: { id: "21", zones: [{ id: "1", readers: [{ id: "1", position: [2, 35, -800], size: [4, 32] }] }], url: "https://tcspinner-pictures.s3.ap-south-1.amazonaws.com/sites/64a151aa8b9b167c5b2940c1/threed-tiles/21.jpg" },
		north: { id: "22" },
		south: { id: "20" },
	},
	{
		tileNode: {
			id: "22",
			zones: [{ id: "1", readers: [{ id: "1", position: [-10, 45, -250], size: [4, 32] }] }],
			url: "https://tcspinner-pictures.s3.ap-south-1.amazonaws.com/sites/64a151aa8b9b167c5b2940c1/threed-tiles/22.jpg",
		},
		south: { id: "21" },
		west: { id: "23" },
	},
	{
		tileNode: {
			id: "23",
			zones: [{ id: "1", readers: [{ id: "1", position: [750, 40, -220], size: [4, 32] }] }],
			url: "https://tcspinner-pictures.s3.ap-south-1.amazonaws.com/sites/64a151aa8b9b167c5b2940c1/threed-tiles/23.jpg",
		},
		east: { id: "22" },
		west: { id: "24" },
	},
	{ tileNode: { id: "24", url: "https://tcspinner-pictures.s3.ap-south-1.amazonaws.com/sites/64a151aa8b9b167c5b2940c1/threed-tiles/24.jpg" }, east: { id: "23" }, west: { id: "25" } },
	{ tileNode: { id: "25", url: "https://tcspinner-pictures.s3.ap-south-1.amazonaws.com/sites/64a151aa8b9b167c5b2940c1/threed-tiles/25.jpg" }, east: { id: "24" }, west: { id: "26" } },
	{
		tileNode: {
			id: "26",
			zones: [{ id: "1", readers: [{ id: "1", position: [380, 160, -400], size: [4, 32] }] }],
			url: "https://tcspinner-pictures.s3.ap-south-1.amazonaws.com/sites/64a151aa8b9b167c5b2940c1/threed-tiles/26.jpg",
		},
		south: { id: "27" },
		east: { id: "25" },
	},
	{
		tileNode: {
			id: "27",
			zones: [{ id: "1", readers: [{ id: "1", position: [210, 90, -600], size: [4, 32] }] }],
			url: "https://tcspinner-pictures.s3.ap-south-1.amazonaws.com/sites/64a151aa8b9b167c5b2940c1/threed-tiles/27.jpg",
		},
		north: { id: "26" },
		south: { id: "28" },
	},
	{
		tileNode: { id: "28", zones: [{ id: "1", readers: [{ id: "1", position: [280, 70, 800], size: [4, 32] }] }], url: "https://tcspinner-pictures.s3.ap-south-1.amazonaws.com/sites/64a151aa8b9b167c5b2940c1/threed-tiles/28.jpg" },
		north: { id: "27" },
		south: { id: "29" },
	},
	{
		tileNode: {
			id: "29",
			zones: [{ id: "1", readers: [{ id: "1", position: [440, 110, 650], size: [4, 32] }] }],
			url: "https://tcspinner-pictures.s3.ap-south-1.amazonaws.com/sites/64a151aa8b9b167c5b2940c1/threed-tiles/29.jpg",
		},
		north: { id: "28" },
		south: { id: "30" },
	},
	{
		tileNode: { id: "30", zones: [{ id: "1", readers: [{ id: "1", position: [500, 120, 20], size: [4, 32] }] }], url: "https://tcspinner-pictures.s3.ap-south-1.amazonaws.com/sites/64a151aa8b9b167c5b2940c1/threed-tiles/30.jpg" },
		north: { id: "29" },
		south: { id: "31" },
	},
	{ tileNode: { id: "31", url: "https://tcspinner-pictures.s3.ap-south-1.amazonaws.com/sites/64a151aa8b9b167c5b2940c1/threed-tiles/31.jpg" }, north: { id: "30" }, south: { id: "32" } },
	{ tileNode: { id: "32", url: "https://tcspinner-pictures.s3.ap-south-1.amazonaws.com/sites/64a151aa8b9b167c5b2940c1/threed-tiles/32.jpg" }, north: { id: "31" }, south: { id: "33" } },
	{
		tileNode: {
			id: "33",
			zones: [{ id: "1", readers: [{ id: "1", position: [-170, 65, 1000], size: [4, 32] }] }],
			url: "https://tcspinner-pictures.s3.ap-south-1.amazonaws.com/sites/64a151aa8b9b167c5b2940c1/threed-tiles/33.jpg",
		},
		north: { id: "32" },
		south: { id: "34" },
	},
	{
		tileNode: {
			id: "34",
			zones: [{ id: "1", readers: [{ id: "1", position: [-195, 75, 800], size: [4, 32] }] }],
			url: "https://tcspinner-pictures.s3.ap-south-1.amazonaws.com/sites/64a151aa8b9b167c5b2940c1/threed-tiles/34.jpg",
		},
		north: { id: "33" },
		south: { id: "3" },
		southWest: { id: "2" },
	},
]
