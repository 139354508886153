import { Clone } from "@react-three/drei"
import { useLoader } from "@react-three/fiber"
import React, { useEffect, useState } from "react"
import { MathUtils } from "three"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"

type PeopleProps = {
	males?: number
	females?: number
	elevation: number
	constraints: number[][][]
}

export const People: React.FC<PeopleProps> = ({ males = 0, females = 0, constraints, elevation }) => {
	const [randomPositions, setRandomPositions] = useState<[number, number, number, number][]>([])

	useEffect(() => {
		if (males + females) {
			const randomPositions: [number, number, number, number][] = []

			for (let i = 0; i < males + females; i++) {
				const pos = getRandomPosition(constraints, elevation, randomPositions)

				if (pos) randomPositions.push(pos)
			}

			setRandomPositions(randomPositions)
		}
	}, [males])

	return (
		<>
			{males ? <Males randomPositions={randomPositions.slice(0, males)} /> : <></>}
			{females ? <Females randomPositions={randomPositions.slice(males)} /> : <></>}
		</>
	)
}

type PersonProps = {
	randomPositions: [number, number, number, number][]
}

const Males: React.FC<PersonProps> = ({ randomPositions }) => {
	const maleModel = useLoader(GLTFLoader, "https://tcspinner-pictures.s3.ap-south-1.amazonaws.com/sites/64a151aa8b9b167c5b2940c1/threed-models/male.glb")

	return randomPositions.map((pos, i) => <Clone key={i} object={maleModel.scene} scale={[40, 40, 40]} rotation={[0, MathUtils.degToRad(pos[3]), 0]} position={[pos[0], pos[1], pos[2]]} />)
}

const Females: React.FC<PersonProps> = ({ randomPositions }) => {
	const femaleModel = useLoader(GLTFLoader, "https://tcspinner-pictures.s3.ap-south-1.amazonaws.com/sites/64a151aa8b9b167c5b2940c1/threed-models/female.glb")

	return randomPositions.map((pos, i) => <Clone key={i} object={femaleModel.scene} scale={[40, 40, 40]} rotation={[0, MathUtils.degToRad(pos[3]), 0]} position={[pos[0], pos[1], pos[2]]} />)
}

const getRandomPosition = (constraints: number[][][], elevation: number, takenPositions: [number, number, number, number][], retryIndex = 1, maxRetries = 4): [number, number, number, number] | null => {
	if (retryIndex === maxRetries) return null

	const i = getRandomNumber(0, constraints.length)

	let minX = Infinity,
		maxX = -Infinity,
		minZ = Infinity,
		maxZ = -Infinity

	constraints[i].forEach(([x, y]) => {
		minX = Math.min(minX, x)
		maxX = Math.max(maxX, x)
		minZ = Math.min(minZ, y)
		maxZ = Math.max(maxZ, y)
	})

	const randomX = Math.floor(Math.random() * (maxX - minX + 1) + minX)
	const randomZ = Math.floor(Math.random() * (maxZ - minZ + 1) + minZ)

	// if (!takenPositions.every((pos) => ensureDistance(randomX, pos[0], 5) && ensureDistance(randomZ, pos[2], 5))) {
	// 	return getRandomPosition(constraints, elevation, takenPositions, retryIndex + 1)
	// }

	return [randomX, elevation, randomZ, getRandomNumber(0, 360)]
}

const getRandomNumber = (min: number, max: number) => {
	return Math.floor(Math.random() * (max - min) + min)
}

// const ensureDistance = (a: number, b: number, distance: number) => Math.abs(a - b) > distance
