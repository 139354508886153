/* eslint-disable @typescript-eslint/indent */
import { animated, useSpring } from "@react-spring/three"
import React from "react"

type RadarAnimationProps = {
	isActive?: boolean
	position: [number, number, number]
	args?:
		| [
				radius?: number | undefined,
				widthSegments?: number | undefined,
				heightSegments?: number | undefined,
				phiStart?: number | undefined,
				phiLength?: number | undefined,
				thetaStart?: number | undefined,
				thetaLength?: number | undefined
		  ]
		| undefined
}

export const RadarAnimation: React.FC<RadarAnimationProps> = ({ isActive, position, args = [4, 32] }) => {
	const { opacity, scale } = useSpring({
		from: { opacity: 0.8, scale: 0 },
		to: { opacity: 0, scale: 10 },
		loop: true,
	})

	return (
		<animated.mesh position={position} scale={scale}>
			<sphereGeometry args={args} />
			{/* @ts-ignore */}
			<animated.meshPhongMaterial attach="material" color={isActive ? "green" : "red"} transparent opacity={opacity} />
		</animated.mesh>
	)
}
